import { TonIcon } from "@/components/Icons/TonIcon";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { SelectLeague } from "@/components/SelectLeague";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useSoccerRounds } from "@/hooks/soccer/useSoccerRounds";
import type { RomanApiError } from "@/lib/net/romanApi";
import { useHomePageStore } from "@/store";
import { getErrorMessage } from "@/utils/error";
import { formatNanoTon } from "@/utils/formatNanoTon";
import { minBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomNavigationBar } from "./BottomNavigationBar";
import { SoccerRoundsCard } from "./SoccerRoundsCard";
import { LEAGUES, type League, SPORTS, type Sport } from "./constants";

export const HomePage = () => {
  const navigate = useNavigate();

  const selectedLeague = useHomePageStore((state) => state.selectedLeague);
  const [selectedSport, setSelectedSport] = useState<Sport>(SPORTS[0]);
  const user = useUserContext();

  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting rounds", message);
    },
    [openModal],
  );

  const { data, isLoading } = useSoccerRounds(handleError);

  const filteredRounds = useMemo(() => {
    if (selectedLeague.seasonId === "all") {
      return data.rounds ?? [];
    }

    return data.rounds.filter((round) => round.seasonId === selectedLeague.seasonId) || [];
  }, [data.rounds, selectedLeague]);

  const activeLeaguesMap = useMemo(() => {
    const activeLeagues = LEAGUES.filter((league) => {
      return data.rounds.some((round) => round.seasonId === league.seasonId);
    });

    return activeLeagues.reduce(
      (acc, league) => {
        acc[league.seasonId] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
  }, [data.rounds]);

  const sortedLeagues = useMemo(() => {
    const [all, ...leagues] = LEAGUES;

    const activeLeagues = leagues.map((league) => {
      const rounds = data.rounds.filter((round) => round.seasonId === league.seasonId);
      return {
        ...league,
        entryCloseTime: minBy(rounds, "entryCloseTime")?.entryCloseTime ?? Number.POSITIVE_INFINITY,
      };
    });

    activeLeagues.sort((a, b) => a.entryCloseTime - b.entryCloseTime);

    return [all].concat(activeLeagues).map(({ seasonId, name, flag }) => ({
      seasonId,
      name,
      flag,
    })) as League[];
  }, [data.rounds]);

  return (
    <div className={`pb-24 ${selectedSport.id !== 1 ? "min-h-screen flex flex-col" : ""}`}>
      <header className="flex flex-col py-4">
        <div className="flex items-center px-2 justify-center mb-3 gap-2">
          <div className="flex justify-center items-center gap-[5px] -translate-x-3">
            {SPORTS.map((sport) => (
              <span
                className="bg-button-background p-1.5 rounded-3xl hover:cursor-pointer"
                onClick={() => setSelectedSport(sport)}
                key={sport.id}
              >
                <sport.icon fill={selectedSport.id === sport.id ? "#5fe311" : "#474747"} />
              </span>
            ))}
          </div>

          <div className="flex justify-center items-center gap-2.5">
            <div
              className="flex items-center justify-center gap-4 border border-chicago-700 rounded-2xl px-2 py-1.5 hover:cursor-pointer"
              onClick={() => navigate("/balance")}
              onKeyDown={(e) => {
                if (e.key === "enter") navigate("/balance");
              }}
            >
              <div className="flex justify-center items-center gap-1">
                <TonIcon />
                <button type="button" className=" text-sm text-white">
                  {formatNanoTon(user.nanoTon)}
                </button>
              </div>
              <img className="bg-button-background p-1.5 rounded-3xl" src="/assets/Union.svg" alt="plus icon" />
            </div>
            <img
              onClick={() => navigate("/my-page")}
              src="/assets/account-icon.svg"
              alt="Account"
              className="hover:cursor-pointer"
            />
          </div>
        </div>

        {selectedSport.id === 1 && (
          <SelectLeague
            className="min-w-[100px]"
            selectedLeague={selectedLeague}
            sortedLeagues={sortedLeagues}
            activeLeaguesMap={activeLeaguesMap}
          />
        )}
      </header>

      {isLoading ? (
        <LoadingSpinner />
      ) : selectedSport.id === 1 && filteredRounds.length ? (
        filteredRounds.map((round) => <SoccerRoundsCard key={round.id} round={round} />)
      ) : (
        <div className="flex-1 flex items-center justify-center h-[70vh]">
          <div className="flex flex-col justify-center items-center text-center">
            <img className="mb-4" src="/assets/thumba-coming-soon.svg" alt="thumba img" />
            <p className="text-xs text-icon">No tournaments in this league</p>
          </div>
        </div>
      )}

      <BottomNavigationBar />
    </div>
  );
};
