import { CustomModal } from "@/components/CustomModal";
import { InfoIcon } from "@/components/Icons";
import type { Player } from "@/types/Player";
import { abbreviateName } from "@/utils/name";
import { type FC, useState } from "react";
import { PlayerFantasyPoints } from "./PlayerFantasyPoints";

interface PlayerCardProps {
  player: Player;
  roundId: string;
  onClick: () => void;
  isSelected: boolean;
  hasAnySelection: boolean;
}

export const PlayerCard: FC<PlayerCardProps> = ({ player, roundId, onClick, isSelected, hasAnySelection }) => {
  const { imageUrl, name, averageFantasyPoint } = player;
  const [isClicked, setIsClicked] = useState(false);
  const [isPlayerStatsOpen, setIsPlayerStatsOpen] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    onClick();
    setTimeout(() => {
      setIsClicked(false);
    }, 100);
  };

  const getCardStyles = () => {
    if (isSelected || isClicked) {
      return {
        padding: "2px",
        backgroundImage: "linear-gradient(to bottom right, #FFE500, #00E218)",
        WebkitBackgroundClip: "padding-box",
        backgroundClip: "padding-box",
      };
    }

    return {
      opacity: hasAnySelection && !isSelected ? "0.5" : "1",
    };
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="rounded-xl hover:cursor-pointer" onClick={handleClick}>
        <div className=" rounded-xl h-full w-[145px]" style={getCardStyles()}>
          <div className="relative flex flex-col bg-chicago-700 rounded-xl h-full w-full" onClick={onClick}>
            <img
              draggable={false}
              src={imageUrl || "/temp/player1.png"}
              alt={name}
              className="rounded-xl object-cover"
            />
            <InfoIcon
              onClick={(e) => {
                e.stopPropagation();
                setIsPlayerStatsOpen(true);
              }}
              className="absolute top-2 right-2 z-10"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-2 gap-1 h-full">
        <p className="text-white text-[14px] overflow-hidden text-ellipsis whitespace-nowrap">{abbreviateName(name)}</p>
      </div>
      <div className="flex justify-center items-center gap-1.5 mt-2 h-full">
        <img
          className="hover:cursor-pointer"
          src="/assets/player-stats.svg"
          alt="stats icon"
          onClick={() => setIsPlayerStatsOpen(true)}
        />
        <div className="relative hover:cursor-pointer" onClick={() => setIsPlayerStatsOpen(true)}>
          <p className="absolute inset-0 flex items-center justify-center text-xs font-bold translate-y-0.5">
            {Number.isInteger(averageFantasyPoint) ? averageFantasyPoint : averageFantasyPoint.toFixed(1)}
          </p>
          <img className="w-9 h-9" src="/assets/player-point.svg" alt="point icon" />
        </div>
      </div>

      <CustomModal
        className="bg-background"
        contentClassName="p-0 w-full h-screen"
        isOpen={isPlayerStatsOpen}
        onClose={() => setIsPlayerStatsOpen(false)}
      >
        <PlayerFantasyPoints player={player} roundId={roundId} />
      </CustomModal>
    </div>
  );
};
