import { BackButton } from "@/components/Buttons/BackButton";
import { Container } from "@/components/Container";
import { TonIcon } from "@/components/Icons/TonIcon";
import Tabs from "@/components/Tabs";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useUserContext } from "@/contexts/UserContext";
import { fromNano } from "@ton/ton";
// import { Deposit } from "./Deposit";
import { Withdraw } from "./Withdraw";

export const BalancePage = () => {
  const user = useUserContext();

  const ton = fromNano(user.nanoTon);

  return (
    <Container className="h-svh flex flex-col">
      <div className="flex mb-2.5">
        <BackButton />
        <div className="flex w-full justify-center">
          <p className="text-white">Balance</p>
        </div>
      </div>

      <div className="flex justify-end">
        <TonUsdPill />
      </div>

      <div className="flex flex-col items-center justify-center py-6">
        <div className="flex gap-2">
          <TonIcon />
          <p className="text-white text-2xl">{ton}</p>
        </div>
      </div>
      <Tabs>
        {/* <Tabs.Item title="Deposit">
          <Deposit />
        </Tabs.Item> */}
        <Tabs.Item title="Withdraw">
          <Withdraw />
        </Tabs.Item>
      </Tabs>
    </Container>
  );
};
